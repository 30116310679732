import {Component, OnInit} from '@angular/core';
import {UPCInputState, UpcService} from 'pm-components';
import {
  Candidate,
  CandidateError,
  CandidateProduct,
  CandidateProductError,
  CandidateValidatorType,
  DistributionChannelPermissions
} from 'pm-models';
import {GrowlService} from '../../growl/growl.service';
import {CandidateService} from '../../service/candidate.service';
import {WorkflowService} from '../../service/workflow.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SupplierProductService} from '../../service/supplier-product.service';
import {NgxPermissionsService} from 'ngx-permissions';
import {SellablePermissions} from 'pm-models/lib/card-models/sellable-model';
import {OwnBrandService} from '../../service/ownbrand.service';
import {NewUpcRequest, UpcRequestType} from 'pm-models/lib/newUpcRequest';
import {NewWarehouseProductStepperComponent} from '../new-warehouse-product-stepper/new-warehouse-product-stepper.component';
import {NewDsdProductStepperComponent} from '../new-dsd-product-stepper/new-dsd-product-stepper.component';
import {UUID} from 'angular2-uuid';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {Observable} from 'rxjs';
import {calculateCheckDigit} from '../../shared/upc.utils';
import {MatUtilService} from '../../service/mat-util.service';
import {finalize, switchMap, tap} from 'rxjs/operators';

@Component({
  selector: 'app-setup-candidate-type',
  templateUrl: './setup-candidate-type.component.html',
  styleUrls: ['./setup-candidate-type.component.scss']
})
export class SetupCandidateTypeComponent implements OnInit {
  constructor(private candidateService: CandidateService, private workflowService: WorkflowService, private route: ActivatedRoute,
              private router: Router, public supplierProductService: SupplierProductService, private growlService: GrowlService,
              private permissionService: NgxPermissionsService, private ownBrandService: OwnBrandService, public upcService: UpcService,
              private candidateUtilService: CandidateUtilService, public matUtilService: MatUtilService) {
  }

  public upcState: UPCInputState;
  public upcOptions = [{label: 'UPC', value: 'UPC'}, {label: 'PLU', value: 'PLU'}];
  public pluTypes = [{label: 'Checker PLU', value: UpcRequestType.CHECKER},
    {label: 'Scale PLU', value: UpcRequestType.SCALE},
    {label: 'Checker & scale PLU', value: UpcRequestType.CHECKER_AND_SCALE}];
  public pluRanges = [];
  public isViewingPage = true;
  public isNextDisabled = false;
  private taskSubscription$: any;
  public upcType: string;
  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;
  public showMatAttributes = false;
  public isLoadingMatData = false;

  readonly sellablePermissions: SellablePermissions = {
    isReadOnly: false,
    isDisabled: false
  };

  channelPermissions: DistributionChannelPermissions = {
    isReadOnly: false,
    isDisabled: true
  };

  ngOnInit() {
    // If there's a previous task/candidate, get it. Else create a new candidate.
    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {
      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.supplierProductService.getTaskId(),
        this.supplierProductService.getTask());

      // if there's params, and it doesn't have a task id equal to the services current task id
      if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
        this.supplierProductService.resetService();
        this.supplierProductService.setCandidateByUrlParameters(params).subscribe((candidate) => {
          this.initializePage();
        });
        // if there's a candidate that hasn't been created (no candidate id), and no task id, then allow for set up page.
      } else if (this.supplierProductService.getOriginalCandidate() && this.supplierProductService.getCandidate() && !taskId
        && !this.supplierProductService.getCandidate().candidateId) {
        this.initializePage();
      } else {
        // if there's a task id and current candidates, get the task by candidate id. If the tasks IDs don't match, reset everything.
        this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.supplierProductService.getCandidate()).subscribe(
          (isValid) => {
            if (isValid) {
              this.initializePage();
            } else {
              this.resetPage();
            }
          });
      }
    });
  }

  initializePage() {
    this.candidateError = this.supplierProductService.getCandidateTypeError();
    this.setDistributionChannelPermissions();
    this.candidateProductError = this.supplierProductService.getCurrentCandidateProductError(this.candidateError);
    this.upcTypeInit();
    this.setupMatAttributes();
  }

  resetPage() {
    window.history.replaceState({}, 'Title', '#/setupCandidateType');
    this.supplierProductService.resetService();
    this.supplierProductService.setupNewCandidate();
    this.initializePage();
  }

  setupMatAttributes() {
    if (!this.permissionService.getPermission('ROLE_CATEGORY_SELECTION-EDIT')) {
      return;
    }
    this.isLoadingMatData = true;
    this.showMatAttributes = true;

    this.matUtilService.updateMatHierarchy(this.supplierProductService.candidate.candidateProducts[0]).pipe(
      tap((hasHierarchyChanges) => {
        this.supplierProductService.hasHierarchyUpdateChanges = hasHierarchyChanges;
      }),
      switchMap(() => this.matUtilService.updateMatAttributesAndValues(this.supplierProductService.candidate,
        this.supplierProductService.globalAttributes, this.supplierProductService.hierarchyAttributes)),
      finalize(() => this.isLoadingMatData = false)
    ).subscribe();
  }


  setDistributionChannelPermissions() {
    this.permissionService.hasPermission('ROLE_DISTRIBUTION_CHANNEL-EDIT').then(hasPermission => {
      if (hasPermission) {
        this.channelPermissions.isDisabled = false;
      } else {
        // if it's not enabled to select fulfillment channel it can only be whs, if it's  whs or it's dsd, set the values to whs, not dsd.
        if (!this.supplierProductService.getCandidate().warehouseSwitch || this.supplierProductService.getCandidate().dsdSwitch) {
          this.supplierProductService.getCandidate().warehouseSwitch = true;
          this.supplierProductService.getCandidate().dsdSwitch = false;
          // force card change detection to pick up change.
        }
      }
    });
  }

  onUpcModelChange() {
    if (this.supplierProductService.getCandidate().candidateProducts[0].upc &&
      this.supplierProductService.getCandidate().candidateProducts[0].upcCheckDigit) {
      this.validateUPC();
    } else {
      this.candidateProductError.upc = null;
      this.candidateProductError.upcCheckDigit = null;
      this.upcState = UPCInputState.none;
    }
  }

  validateUPC() {
    this.upcState = UPCInputState.loading;
    const candidateProductId = this.supplierProductService.getCurrentCandidateProduct().id;

    this.candidateService.validateCandidate(this.supplierProductService.getCandidate(),
      [CandidateValidatorType.UPC_VALIDATOR]).subscribe(() => {
      this.upcState = UPCInputState.valid;
      this.candidateProductError.upc = undefined;
    }, (error) => {
      this.upcState = UPCInputState.invalid;
      // if there's an error, and it's an instance of candidate error model, update the candidate product's
      // upc/checkdigit errors. If there's not a candidate product or this candidate product in the error model,
      // add the whole candidate product error model.
      if (error.error.candidateErrors) {

        const returnedCandidateError: CandidateError = error.error.candidateErrors;
        const returnedCandidateProductError =
          returnedCandidateError.candidateProductErrors[candidateProductId];
        this.candidateProductError.upc = returnedCandidateProductError.upc;
        this.candidateProductError.upcCheckDigit = returnedCandidateProductError.upcCheckDigit;
      } else {
        this.growlService.addError(error.message); // TODO: new way to handle server side errors?
      }
    });
  }

  upcChange() {
    // if it's a dsd item, copy upc values to case upc.
    if (this.supplierProductService.getCandidate() && this.supplierProductService.getCandidate().dsdSwitch &&
      !this.supplierProductService.getCandidate().warehouseSwitch) {
      this.supplierProductService.getCandidate().candidateProducts[0].caseUpc =
        this.supplierProductService.getCandidate().candidateProducts[0].upc;
      this.supplierProductService.getCandidate().candidateProducts[0].caseUpcCheckDigit =
        this.supplierProductService.getCandidate().candidateProducts[0].upcCheckDigit;
    }
    if (this.supplierProductService.getCandidate().candidateProducts[0].upc &&
      this.supplierProductService.getCandidate().candidateProducts[0].upcCheckDigit) {
      this.validateUPC();
    } else {
      this.candidateProductError.upc = null;
      this.candidateProductError.upcCheckDigit = null;
      this.upcState = UPCInputState.none;
    }
  }

  onClickNext() {
    this.isNextDisabled = true;
    // validate the candidate and display errors if any
    this.candidateService.validateCandidate(this.supplierProductService.getCandidate(), [CandidateValidatorType.SUPPLIER_NEW_PRODUCT_SETUP_VALIDATOR]).subscribe(() => {
      this.candidateError = this.supplierProductService.setCandidateTypeError(new CandidateError());
      // if there's a task, then a candidate has already been created. Else one needs to be created. Name must be required.
      if (this.supplierProductService.getTaskId()) {
        this.saveCandidateAndNavigate('/supplierDetails');
      } else {
        if (this.supplierProductService.getCandidate().description) {
          this.supplierProductService.createCandidateAndNavigate(this.supplierProductService.getCandidate(), '/supplierDetails');
        }
      }

    }, (error) => {
      // set the errors on the page
      if (error.error.candidateErrors?.hasErrors) {
        this.candidateError = error.error.candidateErrors;
        this.candidateProductError =
          this.candidateError.candidateProductErrors[this.supplierProductService.getCurrentCandidateProduct().id];
        this.supplierProductService.setCandidateTypeError(this.candidateError);
      }
      // if there's a task, then a candidate has already been created. Else one needs to be created.
      // Name and distribution channel required.
      if (this.supplierProductService.getTaskId() && !this.candidateError.description &&
        !this.candidateError.warehouseSwitch && !this.candidateError.dsdSwitch && !this.candidateProductError?.categorySelectionError) {
        this.saveCandidateAndNavigate('/supplierDetails');
      }
      this.isNextDisabled = false;
    });
  }

  onClickBack() {
    // if a task exists, the candidate can be saved.
    if (this.supplierProductService.getTask() || this.supplierProductService.getTaskId()) {
      this.saveCandidateAndNavigate('/setupCandidate');
    } else {
      // store candidate info, just in case the user returns to the screen.
      this.supplierProductService.setOriginalAndCurrentCandidate(this.supplierProductService.getCandidate());
      this.router.navigate(['/setupCandidate']);
    }
  }

  onClose() {
    // validate the candidate and display errors if any
    this.candidateService.validateCandidate(this.supplierProductService.getCandidate(),
      [CandidateValidatorType.SUPPLIER_NEW_PRODUCT_SETUP_VALIDATOR]).toPromise().then(data => {
      this.candidateError = this.supplierProductService.setCandidateTypeError(new CandidateError());
      // if there's a task, then a candidate has already been created. Else one needs to be created. Name must be required.
      if (this.supplierProductService.getTaskId()) {
        this.saveCandidateAndNavigate('/tasks');
      } else {
        if (this.supplierProductService.getCandidate().description) {
          this.supplierProductService.createCandidateAndNavigate(this.supplierProductService.getCandidate(), '/tasks');
        }
      }
    }, (error) => {
      // set the errors on the page
      if (error.error.candidateErrors?.hasErrors) {
        this.candidateError = error.error.candidateErrors;
        this.candidateProductError =
          this.candidateError.candidateProductErrors[this.supplierProductService.getCurrentCandidateProduct().id];
        this.supplierProductService.setCandidateTypeError(this.candidateError);
      }
      // if there's a task, then a candidate has already been created. Else one needs to be created. Name must be required.
      // Name and distribution channel required.
      if (this.supplierProductService.getTaskId() && !this.candidateError.description &&
        !this.candidateError.warehouseSwitch && !this.candidateError.dsdSwitch) {
        this.saveCandidateAndNavigate('/tasks');
      } else if (this.isPLU() && this.supplierProductService.getCandidate().candidateProducts[0].upc) {
        this.releasePlu().subscribe(() => {
          this.router.navigate(['/tasks']);
        });
      } else {
        this.router.navigate(['/tasks']);
      }
    });
  }

  hasUpcError() {
    return this.candidateProductError && this.candidateProductError.upc;
  }

  channelChange() {
    this.supplierProductService.getCandidate().merchandiseType = null;
    if (this.supplierProductService.getCandidate().warehouseSwitch && this.supplierProductService.getCandidate().dsdSwitch) {
      // TODO: resolve when allowing both.
    } else if (this.supplierProductService.getCandidate().warehouseSwitch) {
      // TODO: This should be resolved when we resolve allowing user to switch from dsd to both
      this.supplierProductService.getCandidate().masterPack = null;
      this.supplierProductService.getCandidate().candidateProducts[0].caseUpc = null;
      this.supplierProductService.getCandidate().candidateProducts[0].caseUpcCheckDigit = null;
      this.supplierProductService.setAuthGroups(null);
      this.supplierProductService.setSelectedAuthGroups(null);
      this.supplierProductService.getCandidate().candidateProducts[0].locationGroupStores = [];
      MatUtilService.removeDsdItemAttributes(this.supplierProductService.getCandidate().candidateProducts[0]);

    } else if (this.supplierProductService.getCandidate().dsdSwitch) {
      this.supplierProductService.getCandidate().codeDate = false;
      this.supplierProductService.getCandidate().warehouseReactionDays = null;
      this.supplierProductService.getCandidate().guaranteeToStoreDays = null;
      this.supplierProductService.getCandidate().maxShelfLife = null;
      this.supplierProductService.getCandidate().inboundSpecDays = null;
      this.supplierProductService.getCandidate().masterPack = null;
      this.supplierProductService.getCandidate().masterLength = null;
      this.supplierProductService.getCandidate().masterWidth = null;
      this.supplierProductService.getCandidate().masterHeight = null;
      this.supplierProductService.getCandidate().masterWeight = null;
      this.supplierProductService.getCandidate().masterListCost = null;
      this.supplierProductService.getCandidate().innerPack = null;
      this.supplierProductService.getCandidate().innerLength = null;
      this.supplierProductService.getCandidate().innerWidth = null;
      this.supplierProductService.getCandidate().innerHeight = null;
      this.supplierProductService.getCandidate().innerWeight = null;
      this.supplierProductService.getCandidate().innerListCost = null;
      this.supplierProductService.getCandidate().masterListCost = null;
      this.supplierProductService.getCandidate().innerPackSelected = null;
      this.supplierProductService.getCandidate().unitCost = null;
      this.supplierProductService.getCandidate().cubeAdjustedFactor = null;
      this.supplierProductService.getCandidate().season = null;
      this.supplierProductService.getCandidate().seasonYear = null;
      this.supplierProductService.getCandidate().maxShip = null;
      this.supplierProductService.getCandidate().lane = null;
      this.supplierProductService.getCandidate().displayReadyUnit = null;
      this.supplierProductService.getCandidate().displayReadyUnitOrientation = null;
      this.supplierProductService.getCandidate().displayReadyUnitRowsDeep = null;
      this.supplierProductService.getCandidate().displayReadyUnitRowsFacing = null;
      this.supplierProductService.getCandidate().displayReadyUnitRowsHigh = null;
      this.supplierProductService.getCandidate().displayReadyUnitType = null;
      this.supplierProductService.getCandidate().candidateProducts[0].warehouses = [];

      this.supplierProductService.getCandidate().candidateProducts[0].caseUpc =
        this.supplierProductService.getCandidate().candidateProducts[0].upc;
      if (this.candidateUtilService.isPlu(this.supplierProductService.getCandidate())) {
        this.supplierProductService.getCandidate().candidateProducts[0].caseUpcCheckDigit = null;
      } else {
        this.supplierProductService.getCandidate().candidateProducts[0].caseUpcCheckDigit =
          this.supplierProductService.getCandidate().candidateProducts[0].upcCheckDigit;
      }

      if (CandidateUtilService.isCostLinkedByItem(this.supplierProductService.getCandidate())) {
        this.supplierProductService.getCandidate().costLinkBy = 'LINK';
        this.supplierProductService.getCandidate().costLink = null;
        this.supplierProductService.getCandidate().costLinkFromServer = null;
      }

      MatUtilService.removeWarehouseItemAttributes(this.supplierProductService.getCandidate().candidateProducts[0]);
    }
  }

  /**
   * Check if the user has changed channel from Warehouse to DSD or vice versa
   * Returns true if it changed.
   */
  hasUserChangedDistributionChannel(): boolean {
    return !!this.supplierProductService.getCandidate()?.dsdSwitch !== !!this.supplierProductService.getOriginalCandidate()?.dsdSwitch;
  }

  /**
   * If candidate product type is PLU, has it changed from scale to not scale (which changes the workflow).
   */
  hasPluTypeChangedWorkflow(): boolean {
    return this.candidateUtilService.isScaleProduct(this.supplierProductService.getCandidate()) !==
      this.candidateUtilService.isScaleProduct(this.supplierProductService.getOriginalCandidate());
  }

  sellableTypeChange(event: Candidate) {
    this.supplierProductService.getCandidate().merchandiseType = null;
    if (event.productType === 'SELLABLE') {
      if (this.supplierProductService.getOriginalCandidate() && this.supplierProductService.getOriginalCandidate().candidateProducts) {
        this.supplierProductService.getCandidate().candidateProducts[0].description =
          this.supplierProductService.getOriginalCandidate().candidateProducts[0].description;
      }
      this.supplierProductService.getCandidate().retailXFor = null;
      this.supplierProductService.getCandidate().retailPrice = null;
      this.supplierProductService.getCandidate().retailSize = null;

    } else {
      this.supplierProductService.getCandidate().retailXFor = 1;
      this.supplierProductService.getCandidate().retailPrice = '0.0';
      this.supplierProductService.getCandidate().retailSize = '1';
      this.supplierProductService.getCandidate().suggestedRetailPrice = null;
      this.supplierProductService.getCandidate().suggestedXFor = null;
      this.supplierProductService.getCandidate().retailSize = '1';
      this.supplierProductService.getCandidate().retailLink = null;
      this.supplierProductService.getCandidate().retailType = null;
    }
  }

  isPLU() {
    return this.upcType === 'PLU';
  }

  isUPC() {
    return this.upcType === 'UPC';
  }

  onUpcTypeChange(event) {
    // this is done to avoid onInit call, which would reset the data.
    if (event === 'PLU' && this.supplierProductService.getCurrentCandidateProduct().candidateProductType === CandidateProduct.PLU) {
      return;
    } else if (event === 'UPC' && !this.supplierProductService.getCurrentCandidateProduct().candidateProductType) {
      return;
    }
    if (this.isUPC()) {
      if (this.supplierProductService.getCandidate().candidateProducts[0].upc) {
        this.releasePlu().subscribe();
      }
      this.supplierProductService.getCandidate().candidateType = Candidate.NEW_PRODUCT;
      this.supplierProductService.getCandidate().candidateProducts[0].upc = null;
      this.supplierProductService.getCandidate().candidateProducts[0].upcCheckDigit = null;
      this.supplierProductService.getCandidate().candidateProducts[0].candidateProductType = null;
      this.supplierProductService.getCandidate().candidateProducts[0].pluRange = null;
      this.supplierProductService.getCandidate().candidateProducts[0].pluType = null;
    } else if (this.isPLU()) {
      this.supplierProductService.getCandidate().candidateType = Candidate.PLU;
      this.supplierProductService.getCandidate().candidateProducts[0].upc = null;
      this.supplierProductService.getCandidate().candidateProducts[0].upcCheckDigit = null;
      this.supplierProductService.getCandidate().candidateProducts[0].candidateProductType = CandidateProduct.PLU;
      this.candidateProductError.upc = null;
    }
  }

  onPluRangeModelChange(event) {
    this.supplierProductService.getCandidate().candidateProducts[0].pluRange = event;
    this.onPluValueChange();
  }

  onPluTypeModelChange(event) {
    this.supplierProductService.getCandidate().candidateProducts[0].pluType = event;

    if (event === UpcRequestType.SCALE || event === UpcRequestType.CHECKER_AND_SCALE) {
      if (! this.supplierProductService.getCandidate().candidateProducts[0].scaleInformation) {
        this.supplierProductService.getCandidate().candidateProducts[0].scaleInformation = { id: UUID.UUID() };
      }
    } else {
      this.supplierProductService.getCandidate().candidateProducts[0].scaleInformation = null;
    }
    this.onPluValueChange();

  }

  onPluValueChange() {
    if (this.supplierProductService.getCandidate().candidateProducts[0].pluType &&
      this.supplierProductService.getCandidate().candidateProducts[0].pluRange) {
      if (this.supplierProductService.getCandidate().candidateProducts[0].upc) {
        this.releasePlu().subscribe();
      }
      this.generatePlu();
    }
  }

  generatePlu() {
    const newUpcRequest = new NewUpcRequest();
    newUpcRequest.requestType = this.supplierProductService.getCandidate().candidateProducts[0].pluType.valueOf();
    newUpcRequest.candidate = this.supplierProductService.getCandidate();
    newUpcRequest.rangeId = this.supplierProductService.getCandidate().candidateProducts[0].pluRange.pluRangeId;
    this.ownBrandService.reserveUpcs(newUpcRequest).subscribe((plus) => {
      this.supplierProductService.getCandidate().candidateProducts[0].upc = plus[0];
      if (this.candidateProductError) {
        this.candidateProductError.upc = null;
      }
    }, (error) =>  {
      if (error.status === 400) {
        this.candidateError = error.error.candidateErrors;
        this.supplierProductService.setCandidateTypeError(this.candidateError);

        if (error.error.validationErrors && error.error.validationErrors.length > 0) {
          this.candidateError.description = error.error.validationErrors[0];
        } else {
          this.candidateError.description = null;
        }

      } else if (error.status === 500) {
        if (this.candidateProductError) {
          this.candidateProductError.upc = 'No UPCs in this range available, please contact procurement support.';
          this.candidateError.description = null;
        }
      } else {
        this.growlService.addError(error.message);
      }
    });
  }

  releasePlu(): Observable<any> {
    return this.ownBrandService.releaseUpc(this.supplierProductService.getCandidate().candidateProducts[0].upc,
      UpcRequestType.CHECKER_AND_SCALE);
  }

  upcTypeInit() {
    if (this.supplierProductService.getCandidate().candidateProducts[0].candidateProductType === CandidateProduct.PLU) {
      this.upcOptions = [{label: 'UPC', value: 'UPC'}, {label: 'PLU', value: 'PLU'}];
      this.upcType = 'PLU';
    } else {
      this.upcType = 'UPC';
    }
  }

  onClickStepper(stepperItem) {
    if (this.isNextDisabled || this.isLoadingMatData) {
      return;
    }
    switch (stepperItem.text) {
      case NewWarehouseProductStepperComponent.SUPPLIER_HEB_SETUP:
        this.onClickNext();
        break;
      case NewWarehouseProductStepperComponent.PRODUCT_DETAILS:
        this.jumpToPage('/basicItemSetup', SupplierProductService.BASE_VALIDATORS);
        break;
      case NewWarehouseProductStepperComponent.CASE_PACK:
        this.jumpToPage('/casePack', SupplierProductService.BASE_VALIDATORS);
        break;
      case NewDsdProductStepperComponent.STORE_AUTHORIZATION:
        if (!this.supplierProductService.getCandidate().candidateProducts[0].locationGroupStores ||
          this.supplierProductService.getCandidate().candidateProducts[0].locationGroupStores.length === 0) {
          this.jumpToPage('/storeAuthorization', SupplierProductService.DSD_VALIDATORS);
        } else {
          this.jumpToPage('/setUpStores', SupplierProductService.DSD_VALIDATORS);
        }
        break;
      case NewWarehouseProductStepperComponent.EXTENDED_ATTRIBUTES:
        if (this.supplierProductService.isDsdOnly()) {
          this.jumpToPage('/extendedAttributes', SupplierProductService.DSD_VALIDATORS);
        } else {
          this.jumpToPage('/extendedAttributes', SupplierProductService.BASE_VALIDATORS);
        }
        break;
      case NewWarehouseProductStepperComponent.REVIEW_PAGE:
        if (this.supplierProductService.isDsdOnly()) {
          this.jumpToPage('/newProductReviewPageComponent', SupplierProductService.DSD_VALIDATORS);
        } else {
          this.jumpToPage('/newProductReviewPageComponent', SupplierProductService.BASE_VALIDATORS);
        }
        break;
    }
  }

  jumpToPage(urlToNavigate, validator) {
    // validate the candidate and display errors if any
    this.candidateService.validateCandidate(this.supplierProductService.getCandidate(), validator).subscribe(() => {
      this.candidateError = this.supplierProductService.setCandidateTypeError(new CandidateError());
      // if there's a task, then a candidate has already been created. Else one needs to be created. Name must be required.
      if (this.supplierProductService.getTaskId()) {
        this.saveCandidateAndNavigate(urlToNavigate);
      } else {
        this.supplierProductService.createCandidateAndNavigate(this.supplierProductService.getCandidate(), urlToNavigate);
      }
    }, (error) => {
      // set the errors on the page
      if (error.error.candidateErrors?.hasErrors) {
        this.candidateError = error.error.candidateErrors;
        this.candidateProductError = this.candidateError.candidateProductErrors[this.supplierProductService.getCurrentCandidateProduct().id];
        if (!this.supplierProductService.isDsdOnly() || !SupplierProductService.DSD_NON_NAVIGABLE_ON_ERROR_PAGES.includes(urlToNavigate)) {
          if (this.supplierProductService.getTaskId()) {
            this.saveCandidateAndNavigate(urlToNavigate);
          } else {
            if (this.supplierProductService.getCandidate().description &&
              (this.supplierProductService.getCandidate().dsdSwitch || this.supplierProductService.getCandidate().warehouseSwitch)) {
              this.supplierProductService.createCandidateAndNavigate(this.supplierProductService.getCandidate(), urlToNavigate);
            }
          }
        }
      }
    });
  }

  getFullPlu() {
    if (this.isPLU()) {
      return this.upcService.pluToPreDigitTwo(this.supplierProductService.getCandidate().candidateProducts[0].upc);
    }
    return '';
  }

  onHierarchySelection(event) {
    this.supplierProductService.candidate.candidateProducts[0].matHierarchyList = event;
    this.supplierProductService.hierarchyAttributes = [];
    this.supplierProductService.hasHierarchyUpdateChanges = false;
    this.matUtilService.updateMatHierarchyErrors(
      this.supplierProductService.getCurrentCandidateProductError(this.supplierProductService.getExtendedAttributesError()),
      this.supplierProductService.globalAttributes, this.supplierProductService.hierarchyAttributes);
    this.supplierProductService.setExtendedAttributesError(JSON.parse(JSON.stringify(this.supplierProductService.getExtendedAttributesError())));
  }

  generate04UPC() {
    this.supplierProductService.getCurrentCandidateProduct().upc = null;
    this.supplierProductService.getCurrentCandidateProduct().upcCheckDigit = null;

    this.ownBrandService.generate04NonSellableUPC().subscribe((upc) => {
      this.supplierProductService.getCurrentCandidateProduct().upc = upc;
      this.supplierProductService.getCurrentCandidateProduct().upcCheckDigit = calculateCheckDigit(upc);
      this.supplierProductService.getCurrentCandidateProduct().generatedUpc = true;
      this.supplierProductService.getCurrentCandidateProduct().id = UUID.UUID();

    }, (error) => {
      this.growlService.addError('Unable to reserve a pre-digit four UPC. Error:' + error.message);
    });
  }

  private saveCandidateAndNavigate(url) {
    if (this.hasUserChangedDistributionChannel() || this.hasPluTypeChangedWorkflow()) {
      if (this.supplierProductService.isEmptyOrSpaces(this.supplierProductService.getCandidate().description)) {
        this.growlService.addError('Failed to save because a candidate name is required.');
      } else {
        this.candidateService.saveCandidate(this.supplierProductService.getCandidate()).subscribe(savedCandidate => {
          this.supplierProductService.setOriginalAndCurrentCandidate(savedCandidate);
          this.supplierProductService.deleteAndCreateNewProcessInstance(url).subscribe();
        });
      }
    } else {
      this.supplierProductService.saveCandidateAndNavigate(url, false).subscribe();
    }
  }
}
